<template>
		<div>
			<div 
				class="hidden-sm-and-down multi-cont"
			>
				<div v-if="blogs==null || blogs.count==0"
					class="full center"
				>
					<div 
						v-if="code==401"
					>
						<h1>{{ this.description }}</h1>
					</div>
					<div 
						v-else 
					>
						<h1>This blog does not exist.</h1>
					</div>
				</div>
				<div
					class="blog_cont"
					v-for="blog in blogs"
					:key="blog.id"
				>
						<div 
							class=full 
							v-if="blog.index==0"
						>
							<a
								v-if="isUpdate"
								href="/investor"
							>
								All Investor Updates 
							</a>
							<a
								v-if="isArticle"
								href="/news"
							>
								All Press Releases 
							</a>
							<a
								v-if="isBlog"
								href="/blogs"
							>
								All Blogs
							</a>
						</div>
						<div class=full
							v-if="$root.investor&&$root.investor.dfAuth==true&&$root.investor.loggedIn&&blog.index==0">
							<v-btn
								v-if="blog.job=='blog'"
								outlined
								v-on:click='newBlog($event)'
							>
								New Blog
							</v-btn>
							<v-btn
								v-if="blog.job=='news'"
								outlined
								v-on:click='newBlog($event)'
							>
								New Press Relese 
							</v-btn>
							<v-btn
								v-if="blog.job=='investor'"
								outlined
								v-on:click='newBlog($event)'
							>
								New Update 
							</v-btn>
							<br>
							<br>
						</div>

						<div class=full>
							Posted on 
							<a 
								:href="'/blog/'+blog.creator_id+'/'"
							>
								{{ blog.readableTimestamp }}
							</a>
						</div>
						<div class="full">
							<div :style="[titleFontStyle]">
								{{ blog.title }}	
								<hr>
							</div>
						</div>
						<div class=left_cont>
							<div v-if="isUpdate==true">
							<a 
								:href="'/updater/'+blog.creator_id+'/'"
							>
								<v-img
									class="rounded"
									alt="Author Profile"
									contain
									:src="blog.profile_pic"
									transition="scale-transition"
									width="70"
								/>
							</a>
							Updated By<br>
							<a 
								:href="'/updater/'+blog.creator_id+'/'"
							>
								{{ blog.first_name }} {{ blog.last_name }}
							</a>
							<div v-if="$root.investor.dfAuth==true&&$root.investor&&$root.investor.loggedIn&&$root.investor.uid==blog.creator_id">
								<a 
									:href="'/edit/'+blog.blog_id+'/'"
								>
									Edit
								</a>
							</div>
							</div>

							<div v-if="isArticle==true">
							<a 
								:href="'/reporter/'+blog.creator_id+'/'"
							>
								<v-img
									class="rounded"
									alt="Author Profile"
									contain
									:src="blog.profile_pic"
									transition="scale-transition"
									width="70"
								/>
							</a>
							Written By<br>
							<a 
								:href="'/reporter/'+blog.creator_id+'/'"
							>
								{{ blog.first_name }} {{ blog.last_name }}
							</a>
							<div v-if="$root.investor&&$root.investor.dfAuth==true&&$root.investor.loggedIn&&$root.investor.uid==blog.creator_id">
								<a 
									:href="'/edit/'+blog.blog_id+'/'"
								>
									Edit
								</a>
							</div>
							</div>

							<div v-if="isBlog==true">
							<a 
								:href="'/blogger/'+blog.creator_id+'/'"
							>
								<v-img
									class="rounded"
									alt="Author Profile"
									contain
									:src="blog.profile_pic"
									transition="scale-transition"
									width="70"
								/>
							</a>
							Posted By<br>
							<a 
								:href="'/blogger/'+blog.creator_id+'/'"
							>
								{{ blog.first_name }} {{ blog.last_name }}
							</a>
							<div v-if="$root.investor&&$root.investor.dfAuth==true&&$root.investor.loggedIn&&$root.investor.uid==blog.creator_id">
								<a 
									:href="'/edit/'+blog.blog_id+'/'"
								>
									Edit
								</a>
							</div>
							</div>
						</div>
						<div class=main_cont>
							<v-img
								class="mx-auto primary_img rounded"
								alt="Blog Header"
								:src="blog.image"
								transition="scale-transition"
							/>
							<br><br>
							<span v-html=blog.body></span>
						</div>	
						<div 
							class=right_cont
							v-if="blog.index==0"
						>
							Recent Blogs 
							<v-divider class="blue-grey"></v-divider>
							<div v-if="recentBlogs==null">
								There are no recent blogs.
							</div>
							<div
								v-for="link in recentBlogs"
								:key="link.id"
							>
								<a
									:href="'/blog/'+link.id+'/'"
								>
									<v-icon>mdi-pencil-box-outline</v-icon> {{ link.title }}	
								</a>
							</div>

							<br><br>

							Recent Press Releases 
							<v-divider class="blue-grey"></v-divider>
							<div v-if="recentNews==null">
								There is no recent news.
							</div>
							<div
								v-for="link in recentNews"
								:key="link.id"
							>
								<a
									:href="'/article/'+link.id+'/'"
								>
									<v-icon>mdi-newspaper-variant-outline</v-icon> {{ link.title }}	
								</a>
							</div>

							<div 
								v-if="$root.investor.isInvestor"
							>
							<br><br>
							Recent Investor Updates 
							<v-divider class="blue-grey"></v-divider>
							<div v-if="recentInvestor==null">
								There is no recent updates.
							</div>
							<div
								v-for="link in recentInvestor"
								:key="link.id"
							>
								<a
									:href="'/investor/'+link.id+'/'"
								>
									<v-icon>mdi-cash</v-icon> {{ link.title }}	
								</a>
							</div>
							</div>
						</div>	
						<div 
							class=right_cont
							v-else
						>
							&nbsp;
						</div>
						<hr class="full end">
				</div>
			</div>
			<div class="hidden-md-and-up">
				<div v-if="blogs==null || blogs.count==0"
					class="full center"
				>
					<h1>This blog does not exist.</h1>
				</div>

				<div
					class="blog_cont"
					v-for="blog in blogs"
					:key="blog.blog_id"
				>
						<div 
							class=mobile
							v-if="blog.index==0"
						>
							Recent Blogs<br>
							<div
								v-for="link in recentBlogs"
								:key="link.id"
							>
								<a
									:href="'/blog/'+link.id+'/'"
								>
									<v-icon>mdi-pencil-box-outline</v-icon>{{ link.title }}	
								</a>
							</div>
							<br>
							Recent Press Releases<br>
							<div
								v-for="link in recentNews"
								:key="link.id"
							>
								<a
									:href="'/article/'+link.id+'/'"
								>
									<v-icon>mdi-newspaper-variant-outline</v-icon>{{ link.title }}	
								</a>
							</div>
							<br>
							<div 
								v-if="$root.investor.isInvestor"
							>
							Recent Investor Updates<br>
							<div
								v-for="link in recentInvestor"
								:key="link.id"
							>
								<a
									:href="'/investor/'+link.id+'/'"
								>
									<v-icon>mdi-cash</v-icon>{{ link.title }}	
								</a>
							</div>
							<br>
							<v-divider class="blue-grey"></v-divider>
							</div>
						</div>	
						<div class="full mobile">
							Posted on 
							<a 
								:href="'/blog/'+blog.creator_id+'/'"
							>
								{{ blog.readableTimestamp }}
							</a>
						</div>
						<div class="full mobile">
							<div :style="[titleFontStyle]">
								{{ blog.title }}	
								<hr>
							</div>
						</div>
						<div class="mobile">
							<a 
								:href="'/blogger/'+blog.creator_id+'/'"
							>
								<v-img
									class="rounded"
									alt="Author Profile"
									contain
									:src="blog.profile_pic"
									transition="scale-transition"
									width="70"
								/>
							</a>
							Written By<br>
							<a 
								:href="'/blogger/'+blog.creator_id+'/'"
							>
								{{ blog.first_name }}
							</a>
						</div>
						<div class="mobile">
							<v-img
								class="mx-auto primary_img rounded"
								alt="Blog Header"
								:src="blog.image"
								transition="scale-transition"
							/>
							<br><br>
							<span v-html=blog.body></span>
						</div>	
				</div>
			</div>
		</div>
</template>

<script>
module.exports = {
	data() {
		return {
			code: 400,
			description: "",
			isArticle: false,
			isBlog: false,
			isUpdate: false,
			blogs: [],
			recentBlogs: [],
			recentNews: [],
			recentInvestor: [],
			titleFontStyle: {},
			titleFontStyleMobile: {},
			textFontStyle: {},
			textFontStyleMobile: {},
		};
	},
	methods: {
		newBlog() {
			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			try {
				headers['USERNAME'] = this.$root.investor.username;
				headers['PASSWORD'] = this.$root.investor.password;
				headers['SESSION'] = this.$root.investor.sessionKey;

				var ep = "/svc/blog/"

				if(this.isArticle) {
					ep = "/svc/news/"
				} else if(this.isUpdate) {
					ep = "/svc/investor/"
				}

				this.$http.put(ep, {}, {headers}).then(response => {
					if(response.data.code==200) {
						var e = '/edit/'+response.data.blog_id+'/'; 
						this.go(e)
					} else {
						alert("Unable to save:  " + response.data.description);
					}
				})
			} catch(e) {
				console.log(e);
			}
		},
		go(href) {
			console.log("going to " + href)
			window.location.href=href
			//this.$router.push({name:href})
		},
		picLinkMO(e) {
			var t = event.target||event.srcElement
			var bg = t.parentElement.children[1];
			var text = t.parentElement.children[2];
			text.style.bottom=this.link.focus.bottom;
			text.style.left=this.link.focus.left;
			text.style.fontSize=this.link.focus.fontSize;
			text.style.color=this.link.focus.fontColor;
			bg.style.opacity = "50%";
			bg.style.bottom="0px";
			bg.style.left="0px";
			bg.style.backgroundColor="black";
			bg.style.width="100%";
			bg.style.height="100%";
		},
		picLinkML(e) {
			var t = event.target||event.srcElement
			var bg = t.children[1];
			var text = t.children[2];
			text.style.bottom=this.link.blur.bottom;
			text.style.left=this.link.blur.left;
			text.style.fontSize=this.link.blur.fontSize;
			text.style.color=this.link.blur.fontColor;
			bg.style.width="";
			bg.style.height="0";
		},
		getReadableDate(ts) {
			console.log(ts);
			var t = new Date(ts*1000);
			//var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
			var options = { year: 'numeric', month: 'long', day: 'numeric' };
			var times = t.toLocaleDateString("en-US", options);
			console.log(times);
			return(times);
		}
	},
	mounted() {
		if(this.$route.path.includes("blog")) {
			this.isBlog=true;
		} else if(this.$route.path.includes("article")) {
			this.isArticle=true;
		} else if(this.$route.path.includes("investor")) {
			this.isUpdate=true;
		}

		console.log("Logged in: " + JSON.stringify(this.$root.$data));
		var headers = {
			'Content-Type': 'application/json;charset=utf-8'
		};

		if(this.$route.path.includes("investor")) {
			headers['USERNAME'] = this.$root.investor.username;
			headers['PASSWORD'] = this.$root.investor.password;
			headers['SESSION'] = this.$root.investor.sessionKey;
		}		

		this.$http.get("/svc"+this.$route.path, {headers}).then(response => {
			this.code = response.data.code;
			this.description = response.data.description;
			this.blogs = response.data.blog;
			
			if(response.data.recents) {
				this.recentBlogs = response.data.recents["blogs"];
				this.recentNews = response.data.recents["news"];
				this.recentInvestor = response.data.recents["investor"];
			}

			if(response.data.global) {
				this.titleFontStyle = response.data.global.title_font_style;
				this.titleFontStyleMobile = response.data.global.title_font_style_mobile;
				this.textFontStyle = response.data.global.text_font_style;
				this.textFontStyleMobile = response.data.global.text_font_style_mobile;
			}

			for(var blog in this.blogs) {

				this.blogs[blog].index = blog;

				this.blogs[blog]["readableTimestamp"] = this.getReadableDate(this.blogs[blog].ts);
				console.log("TS: " + this.blogs[blog]["readableTimestamp"])
				this.blogs[blog].body=decodeURIComponent(escape(window.atob(this.blogs[blog].body)))
			}
		})
	}

};
</script>

<style scoped>
.blog_cont {
	max-width: 1200px;
	height: 100%;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
}

.full {
	clear: both;
}

.end {
	margin: 20px 20px 20px 20px;
}

.left_cont {
	display: inline;
	width: 20%;
	float: left;
}
.right_cont {
	display: inline;
	width: 20%;
	float: left;
}
.main_cont {
	display: inline;
	width: 60%;
	float: left;
}
.primary_img {
	max-height: 200px;
	max-width: 700px;
	object-fit: cover;
}

.mobile {
	margin: 0px 10px 0px 10px;
}

</style>


<style>
blockquote {
	padding: 0 1em;
	border-left: 3px solid rgba(0,0,0,.05);
	margin: 0 0 1.41575em 3em;
	font-style: italic;
}
hr {
	width:80%;
	text-align:left;
	margin-left:0;
	color: rgba(0,0,0,0.05);
}
ol, ul {
	margin: 0 0 1.41575em 3em;
	padding: 0;
}
</style>
